import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAxios, axios);
require("@/assets/styles/manusa.css");
require("@/assets/styles/hrpeople.css");

//{"manusearch":{"rol":"miembro"},"manusanet":{"rol":"miembro"},"manusaacademy":{"rol":"miembro"},"edocuments":{"rol":"miembro"},"rrhh":{"rol":"miembro","contextos":["manuseros.readwrite","cader.readwrite","manuserosviajeros.readwrite"]},"efirmas":false}

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted: function () {
    setTimeout(() => {
      console.log(
        "this.$router",
        this.$router,
        this.$router.history.current.name
      );
      if (
        ["SesionExpirada", "AccesoDenegado"].indexOf(
          this.$router.history.current.name
        ) < 0
      ) {
        let p = new URLSearchParams(window.location.search);
        let token = p.get("token");
        if (!token) {
          token = localStorage.getItem("token");
          console.log("localStorage token", token);
        }
        if (!token) {
          this.$store.commit("CAMBIAR_TOKEN", null);
          this.$store.commit("CAMBIAR_USUARIO", null);
          this.$router.push("/acceso-denegado");
        } else {
          this.obtenerUsuarioSegunToken(token);
          setInterval(() => {
            console.log("Refresco de sesión")
            this.obtenerUsuarioSegunToken(localStorage.getItem("token"));
          }, 300000);
          /*
          if (usuario) {
            this.$store.commit("CAMBIAR_TOKEN", token);
            this.$store.commit("CAMBIAR_USUARIO", usuario);
            console.log("usuario", this.$store.state.usuario);

            // cuando se indica un nuevo token, se reenvía a Home
            if (parameters && parameters.token) {
              document.location.href = "/";
            }
          } else {
            this.$store.commit("CAMBIAR_TOKEN", null);
            this.$store.commit("CAMBIAR_USUARIO", null);
            this.$router.push("/sesion-expirada");
          }
          */
        }
      }
    }, 500);
  },
  methods: {
    obtenerUsuarioSegunToken(token) {
      console.log("token", token);
      var _t = this;
      Vue.axios
        .post(
          process.env.VUE_APP_HOSTPYAPI + "/accion",
          { accion: "mi-sesion" },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          let usuariovalido = false;
          console.log(res.data);
          if (!("error" in res.data && res.data.error !== null)) {
            if (
              res.data.usuario.permisos &&
              res.data.usuario.permisos.rrhh &&
              res.data.usuario.permisos.rrhh.rol &&
              res.data.usuario.permisos.rrhh.contextos
            ) {
              usuariovalido = true;
            }
          } ////console.log(this.getters)

          if (usuariovalido) {
            _t.$store.commit("CAMBIAR_TOKEN", token);
            _t.$store.commit("CAMBIAR_USUARIO", res.data.usuario);
          } else {
            _t.$store.commit("CAMBIAR_USUARIO", null);
            _t.$store.commit("CAMBIAR_TOKEN", null);
          }
        })
        .catch(function (error) {
          console.log(error);
          _t.$store.commit("CAMBIAR_USUARIO", null);
          _t.$store.commit("CAMBIAR_TOKEN", null);
        })
        .finally(function () {
          if (_t.$store.state.usuario) {
            let p = new URLSearchParams(window.location.search);
            if (p.get("token") || _t.$router.history.current.name === "Home")
              document.location.href = "/inicio";
          } else {
            _t.$router.push("/sesion-expirada");
          }
        });

      // return {
      //   nombre: "Angel Ortega",
      //   codigo: "0545",
      //   permisos: ["administrador"],
      //   acronimo: "AO",
      // };
    },
  },
}).$mount("#app");
