import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/inicio",
    name: "Inicio",
    component: () => import("../views/Inicio.vue"),
  },
  {
    path: "/mi-espacio-actualizaciones",
    name: "MiEspacioActualizaciones",
    component: () => import("../views/MiEspacioActualizaciones.vue"),
  },
  {
    path: "/sesion-expirada",
    name: "SesionExpirada",
    component: () => import("../views/SesionExpirada.vue"),
  },
  {
    path: "/acceso-denegado",
    name: "AccesoDenegado",
    component: () => import("../views/AccesoDenegado.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
