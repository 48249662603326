import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
  },
  getters: {
    autenticado: (state) => {
      return state.usuario !== null;
    },
  },
  mutations: {
    CAMBIAR_TOKEN: function (state, v) {
      state.token = v;
      localStorage.setItem("token", v);
    },
    CAMBIAR_USUARIO: function (state, v) {
      state.usuario = v;
    },
  },
  actions: {
    setToken({ commit }, v) {
      commit("CAMBIAR_TOKEN", v);
    },
    setUsuario({ commit }, v) {
      commit("CAMBIAR_USUARIO", v);
    },
  },
  modules: {},
});
